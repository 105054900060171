import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';
const DriverCardHelper = defineComponent({
    name:'DriverCard',
    title:'司机',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            addStatus:false,
            showUpload:false,
            speDriverFleet:false,
            cysData:[],
            fleetData:[],
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: "/driver"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                cysId: [
                    {required: true, message: "请选择承运商", trigger: "blur" }
                ],
                fleetId: [
                    {required: true, message: "请选择入驻车队", trigger: "blur" }
                ],

                name: [
                    {required: true, message: "请输入司机名称", trigger: "blur" }
                ],
                phone: [
                    // {required: true, message: "请输入注册手机", trigger: "blur" }
                    { required: true, validator: utils.$$str.checkPhone, trigger: 'blur' }
                ],
                sfz: [
                    {required: true, message: "请输入身份证号", trigger: "blur" }
                ],
                zjcxType: [
                    {required: true, message: "请选择准驾车型", trigger: "blur" }
                ],
            }
        })
        onMounted(()=>{
        })
        //---------------------------computed---------------------------

        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'cysId'==params.comboId){
                    return dataObj.cysData;
                }

                if(params && 'fleetId'==params.comboId){
                    return dataObj.fleetData;
                }
                if(params && 'type'==params.comboId){
                    return [{value:0,label:'有车司机'},{value:1,label:'无车司机'}]
                }
                if(params && 'jsztType'==params.comboId){
                     return [{value:0,label:'车队'},{value:1,label:'个人'},{value:2,label:'承运商'}]
                }
                if(params && 'zjcxType'==params.comboId){
                    return [{value:0,label:'A1'},{value:1,label:'A2'},{value:2,label:'A3'},{value:3,label:'B1'},{value:4,label:'B2'},{value:5,label:'C1'}]
                }
            }
        })
        const buildCysData=async ()=>{
            dataObj.cysData= await utils.$$api.getCysByChannelId({});
        }

        const buildFleetData=async (cysId,showAllFleet)=>{
            dataObj.fleetData = await utils.$$api.getFleetsByCys({cysId:cysId,showAllFleet:showAllFleet});
        }
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue,selectId)=>{
            if('jsztType'==selectId){}
            if('zjcxType'==selectId){}
            if('cysId'==selectId){
                dataObj.form.cityId='';
                dataObj.form.fleetId='';
                await buildFleetData(newValue);
            }
            if('fleetId'==selectId){
                dataObj.form.cityId='';
                dataObj.form.cysId='';
                dataObj.fleetData.forEach(item=>{
                    if(item.value==newValue){
                        dataObj.form.cityName=item.cityName;
                        dataObj.form.cysId=item.cysId;
                    }
                })
            }
        }
        const disableUpload=()=>{
            dataObj.refMap.get('jsz').disableUpload(true);
            dataObj.refMap.get('hzxy').disableUpload(true);
        }
        const setParam=(cardEngineInst)=>{
            let engineParams=proxy.engine.engineParams;
            //车队明细里面为车辆选择司机会打开司机弹出框列表
            //运力配置那里选择车辆弹出框，再选择车辆弹出框那里，又可以为车辆选择司机，又会打开司机弹出框，都会传入from参数
            if(engineParams.queryParam.from){
                dataObj.disabled=true;
                disableUpload();
                return {from:engineParams.queryParam.from}
            }else{
                return{};
            }
        }
        const buildUploadInitParams=computed(()=>{
            return (params) => {
                return {
                    formId:dataObj.form.id,
                    saveType:1,
                    listType:'picture-card',
                    showFileList:true,
                    showInDialog:true,
                    accept:'.jpg,.jpeg,.png,.JPG,.JPEG,.PBG',
                    uploadModule:'driver',
                    limitNum:params.uploadType=='hzxy'?2:1,
                    uploadType:params.uploadType
                }
            }
        })
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            await buildCysData();
            if('/add'==addOrLoad){
                dataObj.addStatus=true;
            }else {
                dataObj.speDriverFleet=data.speDriverFleet;
                if(data.data.status==0 || data.data.status==-1)dataObj.disabled=false;
                if(data.data.status==2 || data.data.status==3 || data.disabled)dataObj.disabled=true;
                dataObj.showUpload = true;
                await buildFleetData(data.data.cysId,dataObj.speDriverFleet);
                if(data.data.status==2 || data.data.status==3 || data.disabled){
                    dataObj.disabled=true;
                    disableUpload();
                }
                utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'jsz',files:data.data.jszList});
                utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'jszFm',files:data.data.jszFmList});
                utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'hzxy',files:data.data.hzxyList});
            }
        }
        //-----------------------------upload---------------------------
        //上传的时候，增加参数传入到后台
        const buildUploadParams=(formData,params,uploadInst)=>{
            if((uploadInst.uploadParams.saveType)==0){
                //不采用第三方文件服务器，formData才有append方法，否则formData就是一个普通的对象，没有append方法
                formData.append('belongMaxId',dataObj.form.id);
            }else{
                formData.belongMaxId=dataObj.form.id;
            }
        }
        //上传成功之后，需要把本次上传的图片文件追加到上传控件文件数组中，
        const afterResult=(res,uploadInst)=>{
            utils.$$lghdUtils.dealAfterUpload({res:res,uploadInst:uploadInst});
        }
        //-----------------------------upload---------------------------
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const afterSaveHandler=(res,contentName,engineInst)=>{
            if(res.data.status==2 || res.data.status==3){
                dataObj.disabled=true;
            }else{
                dataObj.disabled=false;
            }

            dataObj.refMap.get('jsz').disableUpload(dataObj.disabled);
            dataObj.refMap.get('hzxy').disableUpload(dataObj.disabled);
            return true;
        }
        const startHandler=async()=>{
            utils.$$lghdUtils.startHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const stopHandler=async()=>{
            utils.$$lghdUtils.stopHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const channelClaimHandler=async ()=>{
            await utils.$$lghdUtils.buildClaimDialog({proxy:proxy});
        }
        const sassClaimHandler=async ()=>{
            await utils.$$lghdUtils.buildClaimDialog({proxy:proxy});
        }
        const sureHandler=async (modelInst)=>{
            let res=await utils.$$lghdUtils.claimDialogSure({modelInst:modelInst,proxy:proxy,url: dataObj.compParams.modelPath + "/custom",id:dataObj.form.id});
            return res;
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,beforeOpen,beforeSaveHandler,afterSaveHandler,
            startHandler,stopHandler,setParam,buildUploadInitParams,afterResult,buildUploadParams,channelClaimHandler,sassClaimHandler,sureHandler
        }
    }
});
export default DriverCardHelper;