<template>
    <div class="Driver">
        <el-form ref="formRef" status-icon :model="form" :rules="rules" label-width="100px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="承运商" prop="cysId">
                        <el-select :ref="el=>refMap.set('cysId',el)" v-model="form.cysId" clearable placeholder="所属承运商" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'cysId')}">
                            <el-option v-for="item in cysData" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="入驻车队" prop="fleetId">
                        <el-select :ref="el=>refMap.set('fleetId',el)" v-model="form.fleetId" clearable placeholder="请选择入驻车队" style="width: 100%" :disabled="speDriverFleet?false:disabled" @change="(val)=>{selectOnChange(val,'fleetId')}">
                            <el-option v-for="item in fleetData" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
                                {{ item.label.length>15?item.label.substr(0,15)+'...':item.label }}&nbsp;&nbsp;{{ item.yqm }}
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
<!--                <el-col :span="12">-->
<!--                    <el-form-item label="城市" prop="cityId">-->
<!--                         {{form.cityName}}-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
                <el-col :span="12">
                    <el-form-item label="司机名称" prop="name">
                        <el-input @input="e => form.name = validForbid(e)" v-model="form.name" placeholder="请输入司机名称" maxlength="25" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="司机类别" prop="type">
                        <el-select :ref="el=>refMap.set('type',el)" v-model="form.type" clearable placeholder="请选择司机类别" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'type')}">
                            <el-option v-for="item in comboSelect({comboId:'type'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="手机号码" prop="phone">
                        <el-input @input="e => form.phone = validForbid(e)" v-model="form.phone" placeholder="请输入手机号码" maxlength="11" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="身份证号" prop="sfz">
                        <el-input @input="e => form.sfz = validForbid(e)" v-model="form.sfz" placeholder="请输入身份证号" maxlength="20" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="结算主体" prop="jsztType">
                        <el-select :ref="el=>refMap.set('jsztType',el)" v-model="form.jsztType" clearable placeholder="请选择结算主体" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'jsztType')}">
                            <el-option v-for="item in comboSelect({comboId:'jsztType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="准驾车型" prop="zjcxType">
                        <el-select :ref="el=>refMap.set('zjcxType',el)" v-model="form.zjcxType" clearable placeholder="请选择准驾车型" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'zjcxType')}">
                            <el-option v-for="item in comboSelect({comboId:'zjcxType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="开户行" prop="khh">
                        <el-input @input="e => form.khh = validForbid(e)" v-model="form.khh" placeholder="请输入开户行" maxlength="50" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="卡号" prop="cardNum">
                        <el-input @input="e => form.cardNum = validForbid(e)" v-model="form.cardNum" placeholder="请输入卡号" maxlength="20" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input @input="e => form.remark = validForbid(e)" type="textarea" v-model="form.remark" placeholder="请输入备注" maxlength="3" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-show="!addStatus">
                <el-col :span="12">
                    <el-form-item label="是否停用">
                        <span>{{form.flag==0?'启用':'禁用'}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="创建时间">
                        <span>{{form.time}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
    <div v-show="showUpload" style="margin-top: 20px">
        <el-row>
            <el-col :span="2">驾驶证正面</el-col>
            <el-col :span="10">
                <Upload :="buildUploadInitParams({uploadType:'jsz'})" :ref="el=>refMap.set('jsz',el)"></Upload>
            </el-col>
            <el-col :span="2">驾驶证反面</el-col>
            <el-col :span="10">
                <Upload :="buildUploadInitParams({uploadType:'jszFm'})" :ref="el=>refMap.set('jszFm',el)"></Upload>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="2">合作协议</el-col>
            <el-col :span="10">
                <Upload :="buildUploadInitParams({uploadType:'hzxy'})" :ref="el=>refMap.set('hzxy',el)"></Upload>
            </el-col>
        </el-row>
    </div>
</template>


<script>
    import DriverCardHelper from "./DriverCardHelper.js";
    export default DriverCardHelper;
</script>

<style scoped>
    .Driver{
        width: 100%;
    }
</style>
